<template>
<BootstrapModal :title="title" :id-modal="idModal">
    <template v-slot:body>
        <form class="was-validated">
            <div class="mb-3">
                <label for="input-reference" class="form-label">Text</label>
                <BasicTextArea 
                    v-model="text"
                    :invalidFeedbackText="valErrors.text"
                    textHelp="Notification message."/>
            </div>
            <div class="mb-3">
                <label for="input-registry" class="form-label">Production order</label>
                <SelectInput
                        :options="selectOrders" 
                        :reduceFun="item => item.code"
                        :invalidFeedbackText="valErrors.production_order"
                        v-model="production_order" />
            </div>
        </form>
    </template>
    <template v-slot:footer>
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="submit">{{ submitButtonText }}</button>
    </template>
</BootstrapModal>
</template>

<script>
import { ref, reactive, watch, onMounted } from 'vue';

import BootstrapModal from '../../shared/components/BootstrapModal';
import BasicTextArea from '../../shared/components/BasicTextArea';
import SelectInput from '../../shared/components/SelectInput';

import { getOrders } from '../helpers/endpoints';

export default {
    components: {
        BootstrapModal,
        BasicTextArea,
        SelectInput
    },

    props: {
        title: {
            required: true,
            type: String
        },
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            required: true,
            type: String
        },
        formData: {
            type: Object
        }
    },

    emits: ['submit'],

    setup(props, context) {
        const text = ref('')
        const production_order = ref('')
        
        const valErrors = reactive({text: '', production_order: ''})

        const selectOrders = ref([])

        const cleanValidations = () => {
            for (const field in valErrors) {
                valErrors[field] = ''
            }
        }

        const cleanForm = () =>  {
            cleanValidations()
            text.value = ''
        }

        watch(() => props.formData, (newFormData) => {
            console.log(newFormData);
            if (newFormData) {
                text.value = newFormData.text
                production_order.value = newFormData.production_order
            }
        })

        onMounted(async () => {     
            let ordersGet = await getOrders()
            console.log(ordersGet);
            ordersGet.forEach(element => {
                selectOrders.value.push({ label: element.code, code: element.id })
            })
            
            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })

        })

        return {
            text,
            production_order,
            valErrors,

            selectOrders,

            submit: ($event) => {
                const formData = { text: text.value, production_order: production_order.value }
                console.log(formData);
                context.emit('submit', $event, formData)
            }
        }
    },
}
</script>
