<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Notifications</h1>
    <hr>

    <ModalFormNotification 
        ref="formCreate"
        :idModal="modalFormAddId" 
        title="Add notification" 
        submitButtonText="Add notification"
        :validations="formValidations"
        @submit="createItem"/>

    <ModalFormNotification 
        ref="formEdit"
        :idModal="modalFormEditId" 
        :title="'Edit notification: ' + editData.code" 
        submitButtonText="Edit notification"
        :formData="editData"
        :validations="formValidations"
        @submit="editItem"/>

    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-success"
                    data-bs-toggle="modal" 
                    :data-bs-target="'#' + modalFormAddId"
             ><i class="fa-solid fa-plus"></i> Add</button>
            <button class="btn btn-secondary dropdown-toggle mx-2"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
</div>


</template>


<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"


import { ref } from 'vue';

import { AgGridVue } from "ag-grid-vue3"
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'

import TableButtonEdit from "../../shared/components/TableButtonEdit"
import TableButtonDelete from "../../shared/components/TableButtonDelete"
import ModalFormNotification from '../components/ModalFormNotification'
import { getNotifications, insertNotification, updateNotification, deleteNotification } from '../helpers/endpoints';

import {defaultButtonColumnDef, useGrid} from '../../shared/composables/useGrid'
import { dateRenderer, timeRenderer } from '../../shared/helpers/GridRenderers'
import { dateComparator } from '../../shared/helpers/GridComparators'

export default {
    components: {
        AgGridVue,
        ModalFormNotification
    },
    setup() {
        const modalFormEditId = 'modal-notification-edit';
        const modalFormAddId = 'modal-notification-add';

        const editData = ref({})
        const formValidations = ref({})

        const renderTable = () => {
            getNotifications().then((response) => {
                rowData.value = response;
                console.log(rowData.value);
            })
        }

        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(renderTable)

        const openEditModal = (formData) => {
            console.log(formData);
            editData.value = formData

            let modalEdit = new Modal(document.getElementById(modalFormEditId))
            modalEdit.show()
        }

        const createItem = ($event, formData) => {
            console.log("Adding notification");
            console.debug($event);
            console.debug(formData);
            insertNotification(formData).then((response) => {
                console.log(response);
                renderTable()

                let modalCreate = Modal.getInstance(document.getElementById(modalFormAddId))
                modalCreate.hide()

                Swal.fire(
                    'Good job!',
                    'Notification added with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })
        }

        const editItem = ($event, formData) => {
            console.log("Editing notification");
            console.log($event);
            console.log(formData);
            updateNotification(editData.value.id, formData).then((response) => {
                console.log(response);
                renderTable()

                let modalEdit = Modal.getInstance(document.getElementById(modalFormEditId))
                modalEdit.hide()
                
                Swal.fire(
                    'Good job!',
                    'Notification edited with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })

        }

        const removeItem = ({ id }) => {
            console.log(`Deleting notification ${id}`);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    deleteNotification(id)
                        .then((response)=> {
                            console.log(response)
                            renderTable()
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'Notification has been deleted.',
                                'success'
                            )
                        }).catch((error) => {
                            console.log(error.response);
                            const { data } = error.response;
                            if (data.message) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: data.message
                                })
                            }
                        })
                    
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your notification is safe :)',
                        'error'
                    )
                }
            })
        }

        const getColumnDefs = () => [
            { headerName: "Production order", field: "production_order_code", filter: 'agTextColumnFilter' },
            { headerName: "Text", field: "text", filter: 'agTextColumnFilter' },
            { headerName: "User", field: "user_repr", filter: 'agTextColumnFilter' },
            { 
                headerName: "Date", 
                field: "time",
                filter: 'agDateColumnFilter',
                cellRenderer: dateRenderer,
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: dateComparator
                } 
            },
            { 
                headerName: "Time", 
                field: "time", 
                cellRenderer: timeRenderer 
            },
            {
                field: 'id',
                cellRenderer: 'btnEditRenderer',
                cellRendererParams: {
                    action: openEditModal
                },
                ...defaultButtonColumnDef
            },
            {
                field: 'id',
                cellRenderer: 'btnDeleteRenderer',
                cellRendererParams: {
                    action: removeItem
                },
                ...defaultButtonColumnDef
            }
        ]

        return {
            modalFormEditId,
            modalFormAddId,

            columnDefs: getColumnDefs(),
            defaultColDef,
            frameworkComponents: {
                btnEditRenderer: TableButtonEdit,
                btnDeleteRenderer: TableButtonDelete
            },
            rowData,
            editData,
            formValidations,
            onBtnExport: () => exportAsCSV([
                    'code',
                    'user',
                    'date',
                    'time',
                ]),
            onGridReady,

            createItem,
            editItem
        }
    }
}
</script>
<style lang="scss" scoped>
</style>